import React, { Component } from 'react';

class Education extends Component {
  render() {

    if (this.props.data) {
      var education = this.props.data.education.map(function (education) {
        return <div key={education.school}><h3>{education.school}</h3>
          <p className="info">{education.degree}<br /> <span>&bull;</span><em className="date">{education.graduated}</em><br /></p>
          <div>
            {education.description}
            {education.awards.length > 0 ? <div className="awards">
              {
                education.awards.map((award) => {
                  return (
                    <i key={award}>&nbsp;- {award}<br /></i>
                  )
                })
              }
            </div>
              : ""}
          </div>
          <br/>
          <br/>
        </div>
      })
    }

    return (
      <section id="education">
        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1><br/>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns" style={{paddingTop:"9px"}}>
                {education}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Education;
