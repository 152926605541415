import React, { Component } from 'react';
import emailjs from 'emailjs-com';

class Contact extends Component {
   constructor(props) {
      super(props);
      this.state = {
         name: '',
         email: '',
         subject: '',
         message: '',
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
   }

   handleChange(event) {
      const name = event.target.name;
      this.setState({
         [name]: event.target.value
      });
   }

   handleSubmit(event) {
      // alert('A form was submitted: ' + this.state.subject);
      event.preventDefault();
      // console.log(event.target);
      emailjs.sendForm('yibinlim1995gmail', 'contact_form', event.target, 'user_2jEJOwSKKiZ7Wkg6YavU1')
         .then(() => {
            alert('The message was sent successfully! Thank you for reaching out and I will get back to you shortly :)');
            this.setState({
               name: '',
               email: '',
               subject: '',
               message: '',
            });
         }, () => {
            alert('Unfortunately, there seems to be an error with the messaging system :( Feel free to contact me via email or Telegram instead!');
         });
   }

   render() {

      if (this.props.data) {
         var name = this.props.data.name;
         var area = this.props.data.address.area;
         var country = this.props.data.address.country;
         var email = this.props.data.email;
         var telegramHandler = this.props.data.telegramHandler;
         var telegramLink = this.props.data.telegramLink;
         var message = this.props.data.contactmessage;
         var resumeDownload = this.props.data.resumedownload;
      }

      return (
         <section id="contact">
            <div className="row section-head">
               <div className="two columns header-col">
                  <h1><span>Get In Touch.</span></h1>
               </div>
               <div className="ten columns">
                  <p className="lead">{message}</p>
               </div>
            </div>
            <div className="row">
               <div className="eight columns">
                  <form id="contactForm" name="contactForm" onSubmit={this.handleSubmit}>
                     <fieldset>
                        <div>
                           <label htmlFor="contactName">Name <span className="required">*</span></label>
                           <input name="name" type="text" value={this.state.name} size="35" id="contactName" onChange={this.handleChange} required />
                        </div>

                        <div>
                           <label htmlFor="contactEmail">Email <span className="required">*</span></label>
                           <input name="email" type="text" value={this.state.email} size="35" id="contactEmail" onChange={this.handleChange} required />
                        </div>

                        <div>
                           <label htmlFor="contactSubject">Subject <span className="required">*</span></label>
                           <input name="subject" type="text" value={this.state.subject} size="35" id="contactSubject" onChange={this.handleChange} required />
                        </div>

                        <div>
                           <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                           <textarea name="message" value={this.state.message} cols="50" rows="15" id="contactMessage" onChange={this.handleChange} required></textarea>
                        </div>

                        <div>
                           <label />
                           <input value="Send" type="submit" className="submit" />
                        </div>
                     </fieldset>
                  </form>
                  {/* <div id="message-warning"> Error boy</div>
                  <div id="message-success">
                     <i className="fa fa-check"></i>Your message was sent, thank you!<br />
                  </div> */}
               </div>

               <aside className="four columns footer-widgets">
                  <div className="widget widget_contact">
                     <h4>Contact Details</h4>
                     <p className="address">
                        <span className="cotactDetails">{name}</span><br />
                        <span className="cotactDetails">{area},{country}</span><br />
                        <span className="cotactDetails">Telegram: <a href={telegramLink} target="_blank" rel="noopener noreferrer">{telegramHandler}</a></span><br />
                        <span className="cotactDetails"><a href="mailto:yibinlim1995@gmail.com">{email}</a></span>
                        <center>
                           <a href={resumeDownload} target="_blank" rel="noopener noreferrer" className="button"><i className="fa fa-download"></i>Download Resume</a>
                        </center>
                     </p>
                     </div>
               </aside>
            </div>
         </section >
      );
   }
}

export default Contact;
