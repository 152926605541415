import React, { Component } from 'react';
import { CSSTransition } from "react-transition-group";

class Header extends Component {
   render() {
      var delay = 8000;

      if (this.props.data) {
         var name = this.props.data.name;
         var occupation = this.props.data.occupation;
         var employer = this.props.data.employer;
         var description = this.props.data.description;
         var country = this.props.data.country;
         var networks = this.props.data.social.map(function (network) {
            return <li key={network.name}><a href={network.url} target="_blank" rel="noopener noreferrer"><i className={network.className}></i></a></li>
         })
      }

      return (
         <header id="home">

            <nav id="nav-wrap">

               <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
               <a className="mobile-btn" href="#nav-wrap > a.mobile-btn" title="Hide navigation">Hide navigation</a>

               <ul id="nav" className="nav">
                  <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                  <li><a className="smoothscroll" href="#about">About</a></li>
                  <li><a className="smoothscroll" href="#work">Work</a></li>
                  <li><a className="smoothscroll" href="#education">Education</a></li>
                  <li><a className="smoothscroll" href="#tech">Tech</a></li>
                  <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li>
                  <li><a className="smoothscroll" href="#contact">Contact</a></li>
               </ul>
            </nav>

            <div className="row banner">
               <div className="banner-text">
                  <CSSTransition
                     in={true}
                     appear={true}
                     timeout={delay}
                     classNames="fadeHeader"
                  >
                     <h1 className="responsive-headline">Hello World!</h1>
                  </CSSTransition>
                  <CSSTransition
                     in={true}
                     appear={true}
                     timeout={delay}
                     classNames="fadeDesc"
                  >
                     <h3>
                        <span>Welcome to my personal site! My name is {name}, a {occupation} based in {country}. 
                        {description} {employer} but feel free to contact me if you are interested to collaborate on a project! :)</span>
                        {/* <span>Welcome to my personal site!</span> My name is <span>{name}</span>, a <span>{occupation}</span> based in <span>{country}</span>. 
                        {description} <span>{employer}</span> but feel free to contact me if you are interested to <span>collaborate</span> on a project! <span>:)</span> */}
                     </h3>
                  </CSSTransition>
                  <CSSTransition
                     in={true}
                     appear={true}
                     timeout={delay}
                     classNames="fadeDesc"
                  >
                     <hr />
                  </CSSTransition>
                  <CSSTransition
                     in={true}
                     appear={true}
                     timeout={delay}
                     classNames="fadeSocial"
                  >
                     <ul className="social">
                        {networks}
                     </ul>
                  </CSSTransition>
               </div>
            </div>

            <p className="scrolldown">
               <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
            </p>

         </header>
      );
   }
}

export default Header;
