import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var profilepic= "/images/"+this.props.data.image;
      var quote = this.props.data.quote;
      var quoteBy = this.props.data.quoteBy;
      var bio1 = this.props.data.bio1;
      var bio2 = this.props.data.bio2;
      var area = this.props.data.address.area;
      var country = this.props.data.address.country;
      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;
      var telegramHandler = this.props.data.telegramHandler;
      var telegramLink = this.props.data.telegramLink;
    }

    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic" alt={"profile-pic"} src={profilepic} />
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>
               <i>
                  {quote}<br/>
                  {quoteBy}<br/><br/>
               </i>  
               {bio1}
               <br/><br/>
               {bio2}
            </p>
            <div className="row">
               <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
						   <span>{name}</span><br />
						   <span>{area},{country}</span><br />
						   {/* <span>{phone}</span><br /> */}
                     <span>Telegram: <a href={telegramLink} target="_blank" rel="noopener noreferrer">{telegramHandler}</a></span><br/>
                     <span><a href="mailto:yibinlim1995@gmail.com">{email}</a></span>
					   </p>
               </div>
               <div className="columns download">
                  <p>
                     <a href={resumeDownload} target="_blank" rel="noopener noreferrer" className="button"><i className="fa fa-download"></i>Download Resume</a>
                  </p>
               </div>
            </div>
         </div>
      </div>

   </section>
    );
  }
}

export default About;
