import React, { Component } from 'react';

class Tech extends Component {
  render() {

    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var skills = this.props.data.skills.map(function (skills) {
        var projectImage = 'images/tech/' + skills.image;
        return <div key={skills.name} className="columns feature-item">
          <img className='skill' alt={skills.name} src={projectImage} />
          <h5>{skills.name}</h5>
          <p>{skills.description}</p>
          <br/>
        </div>
      })
    }

    return (
      <section id="tech">
        <div className="row skill">
          <div className="three columns header-col">
            <h1><span>Favorite Tech</span></h1><br/>
          </div>

          <div>
            <div className="nine columns main-col">
              <p className="lead center">{skillmessage}</p></div>
              <ul className="bgrid-quarters s-bgrid-thirds cf">
              {skills}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Tech;
