import React, { Component } from 'react';

class Testimonials extends Component {
  render() {

    if(this.props.data){
      var testimonials = this.props.data.testimonials.map(function(testimonials){
        return  <li key={testimonials.user}>
            <blockquote>
               <p><strong>"</strong> {testimonials.text} <strong>"</strong></p>
               <cite><i>{testimonials.user}</i></cite>
            </blockquote>
         </li>
      })
    }

    return (
      <section id="testimonials">
      <div className="text-container">
         <div className="row">

            <div className="one columns header-col">
               <h1><span>Client Testimonials</span></h1>
            </div>
            <div className="ten columns header-col">
               <ul>
                     {testimonials}
               </ul>
            </div>
            <div className="one columns header-col">
               <h1><span> </span></h1>
            </div>
            </div>
         </div>
   </section>
    );
  }
}

export default Testimonials;
